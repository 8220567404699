import React, {
    createContext,
    useContext,
    useMemo,
} from "react";

import { ITableRow, getEntityRow } from "~/components/Table";
import { ctaColumns } from "./columns";
import { ApolloError } from "@apollo/client";
import { IComponentNavigateFn } from "@utils/navigate";
import { useCompany } from "@api/companies";
import { CTA } from "@api/ctas";

export interface ProviderProps {
    children?: any;
    navigate: IComponentNavigateFn;
    ctaId?: string;
    params?: Record<string, string>;
}

type Group = {
    ctas: CTA[];
};

export type { Group };

interface ICTAContextActions {
}

interface IDisplayGroup {
    group?: Group;
    rows: ITableRow[];
}

export interface CTAOverride {
    theme: string;
    templateDef: {
        message: string[];
    };
    themeDef: any;
    delete?: boolean;
}

interface ICTAContext extends ICTAContextActions {
    loading: boolean;
    displayGroup?: IDisplayGroup;
    ctas?: { companyCtas: CTA[]; loading: boolean; error: ApolloError | undefined; };
    selectedCta?: CTA;
    state: any;
    saveCTA: (input: CTAOverride) => void;
}

interface CtaMap { [key: string]: CTA[] }

export const CTAContext = createContext({} as ICTAContext);

export const useCTAContext = () => {
    const context = useContext(CTAContext);
    if (!context) {
        throw new Error("You cannot use the CTA Context outside of its Provider!");
    }
    return context;
};

export const CTAProvider = (props: ProviderProps) => {
    const { children, navigate, params: { companyId } = { companyId: "" }, ctaId } = props;
    const { state, actions, loading } = useCompany(parseInt(companyId));
    const selectedCta = useMemo(() => {
        return state.companyCtas.find((cta) => cta.id === ctaId);
    }, [state, ctaId]);

    const displayGroup = useMemo(() => {
        const getCTAPath = (ctaId: string) => (
            `/admin/dealer-management/${companyId}/ctas/${ctaId}/`
        );

        const handleSelect = (cta: {id: string}) => () => {
            navigate(getCTAPath(cta.id));
        };

        const _ctas: CtaMap = state.companyCtas
            .reduce((agg: CtaMap, cta: CTA) => {
                const name = `${cta.product}#${cta.type}`;
                if (!agg[name]) {
                    agg[name] = [];
                }
                agg[name].push(cta);

                return agg;
            }, {});

        const ctas: CTA[] = Object.values(_ctas)
            .map((arr) => {
                if (!arr.length) {
                    return null;
                }
                return arr.reduce((prev, current) => {
                    if (prev.companyCta) {
                        return prev;
                    }
                    if (current.companyCta) {
                        return current;
                    }
                    return (prev.version > current.version) ? prev : current;
                }) as CTA;
            }).filter(Boolean) as CTA[];

        const rows = ctas
            .map((cta: CTA, ctaIdx: number) => ({
                id: cta.id,
                values: getEntityRow<CTA>(
                    ctaColumns,
                    cta,
                    ctaIdx,
                ),
                select: handleSelect(cta),
            }));

        return {
            rows,
        };
    }, [state, navigate, companyId]);

    const ctaContext = useMemo<ICTAContext>(() => {
        const saveCTA = (input: CTAOverride) => {
            if (!selectedCta) {
                alert("Selected CTA is missing!");
                return;
            }
            const variables = {
                "id": selectedCta.id,
                "updatedAt": selectedCta.updatedAt,
                "companyOverride": {
                    ...input,
                    "default": true,
                    "locked": false,
                    "updatedAt": selectedCta.companyCta?.updatedAt || selectedCta.updatedAt,
                    "groupId": state.primaryGroup!,
                    "name": selectedCta.companyCta?.name || `${state.name} Default ${selectedCta?.product} ${selectedCta?.type}`,
                },
            };
            actions.override({
                variables,
            });
        };

        return {
            displayGroup,
            loading: loading,
            selectedCta,
            saveCTA,
            state,
        };
    }, [
        displayGroup,
        loading,
        selectedCta,
        actions,
        state,
    ]);

    return (
        <CTAContext.Provider value={ctaContext}>
            {children}
        </CTAContext.Provider>
    );
};