import React, {
    Fragment,
    useEffect,
    useState,
    useMemo,
} from "react";
import { makeStyles } from "@material-ui/core/styles";

import CodeEditor from "@uiw/react-textarea-code-editor";
import { CTAOverride, useCTAContext } from "../_lib/context";

import { CTAProvider } from "../_lib/context";
import { CTA as CtaIframe } from "./_lib/cta";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { PrimaryButton } from "~/components/Button";
import { createCTAView, ViewComponent } from "./_lib/view";
import { useAuth } from "@api/auth";
import { FeedbackProvider, useFeedbackContext } from "~/components/Feedback/context";
import { Redirect } from "~/components/Redirect";
import { Typography } from "~/components/Typography";
import { CTA } from "@api/ctas";
import { Loading } from "../../../_lib/Loading";
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => {
    return ({
        column: { width: "50%", padding: "15px" },
        buttonContainer: { display: "flex", justifyContent: "flex-end", marginTop: "15px" },
        updateButton: { marginRight: "15px" },
        deleteButton: { background: "#C3002E", boxShadow: "none" },
    });
});

const defaultOverride = {
    "templateDef": {
        "message": [""],
    },
    "theme": "default",
    "themeDef": {},
};

const f = (selectedCta: any, companyCta?: CTAOverride) => {
    if (companyCta) {
        return JSON.stringify({
            templateDef: companyCta.templateDef || selectedCta?.templateDef.defs.default || defaultOverride.templateDef,
            themeDef: companyCta.themeDef || selectedCta?.themeDef.defs.default || defaultOverride.themeDef,
        }, null, 2);
    }

    return JSON.stringify({
        templateDef: defaultOverride.templateDef,
        themeDef: defaultOverride.themeDef,
    }, null, 2);
};

const titleCase = (s: string) =>
    s.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())
        .replace (/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

const CompanyView: ViewComponent = () => {
    const { user } = useAuth();
    const { handleOpenFeedback } = useFeedbackContext();

    const {
        selectedCta,
        saveCTA,
        loading,
        state,
    } = useCTAContext();
    const styles = useStyles();

    const widgetId = useMemo(() => {
        return state?.widgets?.nodes?.[0]?.id || "";
    }, [state]);

    const companyCta = useMemo(() => {
        return (selectedCta?.companyCta || {}) as CTA["companyCta"];
    }, [selectedCta]);

    const themes: string[] = useMemo(() => {
        return (selectedCta?.themes || []) as string[];
    }, [selectedCta]);

    const [theme, setTheme] = useState(
        companyCta?.theme || defaultOverride.theme,
    );
    const [code, setCode] = useState(
        f(companyCta),
    );

    useEffect(() => {
        setCode(f(selectedCta, companyCta));
        setTheme(companyCta?.theme || defaultOverride.theme);
    }, [selectedCta, companyCta]);

    // const [alignment, setAlignment] = useState("simple");
    // const handleChange = (
    //     event: React.MouseEvent<HTMLElement>,
    //     newAlignment: string,
    // ) => {
    //     setAlignment(newAlignment);
    // };

    const content = useMemo(() => {
        const ctaProps = { product: selectedCta?.product, type: selectedCta?.type, company: widgetId };
        const buttonText = !selectedCta?.companyCta ? "Create" : "Update";
        const save = (props: { delete?: boolean }) => {
            try {
                const cta = JSON.parse(code);
                saveCTA({ theme, templateDef: cta.templateDef, themeDef: cta.themeDef, ...props });
                if (props.delete) {
                    handleOpenFeedback({message: "Custom CTA deleted.", severity: "warning" });
                } else if (!selectedCta?.companyCta) {
                    handleOpenFeedback({message: "Custom CTA created.", severity: "success" });
                } else {
                    handleOpenFeedback({message: "Custom CTA updated.", severity: "success" });
                }
            } catch (err) {
                console.log(err);
                handleOpenFeedback({message: "Must submit valid JSON.", severity: "error" });
            }
        };
        const updateCTA = () => {
            return save({});
        };
        const deleteCTA = () => {
            return save({ delete: true });
        };

        if (!user?.autocorpAdmin) {
            return <Redirect to="/leads/" />;
        }


        return (
            <Grid container>
                <Grid item className={styles.column}>
                    {loading ? <Loading /> : <CtaIframe {...ctaProps} />}
                </Grid>
                <Grid item className={styles.column}>
                    {/* <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value="simple">Simple</ToggleButton>
                            <ToggleButton value="advanced">Advanced</ToggleButton>
                        </ToggleButtonGroup> */}
                    {!!theme && <FormControl fullWidth>
                        <InputLabel id="cta-theme-label">Theme</InputLabel>
                        <Select
                            labelId="cta-theme-label"
                            id="cta-theme"
                            value={theme}
                            label="Theme"
                            onChange={(e) => setTheme(e.target.value as string)}
                        >
                            {themes.map((theme) => {
                                return <MenuItem key={theme} value={theme}>{titleCase(theme)}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>}
                    <Typography variant="h4" paragraph>Template</Typography>
                    <CodeEditor
                        value={code}
                        language="json"
                        placeholder="Please enter JSON code."
                        onChange={(evn: React.ChangeEvent<HTMLTextAreaElement>) => setCode(evn.target.value)}
                        padding={15}
                        style={{
                            fontSize: 12,
                            backgroundColor: "#f5f5f5",
                            fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                        }}
                    />
                    <div className={styles.buttonContainer}>
                        <PrimaryButton onClick={updateCTA} className={styles.updateButton}>{buttonText}</PrimaryButton>
                        <PrimaryButton onClick={deleteCTA} className={styles.deleteButton}>Delete</PrimaryButton>
                    </div>
                </Grid>
            </Grid>
        );

    }, [
        loading,
        user,
        styles,
        code,
        setCode,
        saveCTA,
        handleOpenFeedback,
        theme,
        themes,
        selectedCta,
        widgetId,
    ]);

    return (
        <Fragment>
            {content}
        </Fragment>
    );
};

const CTAView: ViewComponent = (props) => {
    return (
        <FeedbackProvider>
            <CTAProvider {...props}>
                <CompanyView {...props} />
            </CTAProvider>
        </FeedbackProvider>
    );
};

export default createCTAView(CTAView, {});