import { createPrivateView, ViewComponent } from "~/model/view/private";
import { getCompanyId } from "../../../../_lib/companyId";
import { CTAProvider } from "../../_lib/context";
import { CTAViewLayout } from "./CTALayout";

export const createCTAView: typeof createPrivateView = (
    component,
    props = {},
) => {
    const companyId = getCompanyId();

    return createPrivateView(
        component,
        {
            ...props,
            showBack: true,
            backTo: `/admin/dealer-management/${companyId}/ctas/`,
            layoutProps: {
                LayoutContextProvider: CTAProvider,
                topNav: {
                    sub: CTAViewLayout,
                },
            },
        },
    );
};

export type { ViewComponent };

