import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ProductType } from "@api/products";

interface CTAProps {
    product?: ProductType;
    type?: string;
    company?: string;
}

const useStyles = makeStyles((theme) => {
    return ({
        iframe: {
            width: "100%",
            height: "500px",
        },
    });
});

export const CTA = ({product, type, company}: CTAProps) => {
    const styles = useStyles();
    if (!product || !type || !company) {
        return null;
    }
    return (
        <iframe
            className={styles.iframe}
            src={`/cta-preview/index.html?avaPreview=1&product=${product}&type=${type}&company=${company}`}
            frameBorder="0"
            scrolling="no"
        />
    );
};
