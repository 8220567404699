import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles/colorManipulator";
import clsx from "clsx";

const animationSpeed = 2;
const animationTime = "cubic-bezier(0.5, 0.3, 0.4, 0.5)";
const radius = 43;
const startingRotation = 267;

const dashLimit = (percentage: number): number => {
    const circ = 2 * 3.1415927 * radius;
    const perc = percentage / 100;
    return circ * perc;
};

const useStyles = makeStyles<Theme, ISpinnerProps>(({ palette }) => ({
    root: {
        animation: `$spinnerSvg ${animationSpeed * 2}s linear infinite`,
        height: "100%",
        width: "100%",
        shapeRendering: "geometricPrecision",
    },
    route: (props) => ({
        stroke: props.fill || palette.border.main,
        strokeWidth: 10,
        fill: "transparent",
        shapeRendering: "geometricPrecision",
    }),
    ani: (props) => ({
        stroke: props.fill ? darken(props.fill, .5) : palette.border.dark,
        strokeWidth: 12,
        fill: "transparent",
        strokeDasharray: dashLimit(100),
        strokeDashoffset: dashLimit(50),
        strokeLinecap: "round",
        transformOrigin: "50% 50%",
        transform: `rotate(${startingRotation})`,
        shapeRendering: "geometricPrecision",
    }),
    aniKeyframe: {
        animation: `$spinnerAni ${animationSpeed}s ${animationTime} infinite both`,
    },
    "@keyframes spinnerSvg": {
        "0%": {
            transform: "rotateZ(0deg)",
        },
        "100%": {
            transform: "rotateZ(360deg)",
        },
    },
    "@keyframes spinnerAni": {
        "0%": {
            strokeDashoffset: dashLimit(97),
            transform: `rotate(${startingRotation}deg)`,
        },
        "50%": {
            strokeDashoffset: dashLimit(50),
            transform: `rotate(${startingRotation + 90}deg)`,
        },
        "100%": {
            strokeDashoffset: dashLimit(97),
            transform: `rotate(${startingRotation + 360}deg)`,
        },
    },
}));

interface ISpinnerProps {
    fill?: string;
    className?: string;
    style?: React.CSSProperties;
}

export const Spinner: React.FC<ISpinnerProps> = (props) => {
    const styles = useStyles(props);
    const { style, className } = props;

    return (
        <svg style={style} className={clsx(styles.root, className)} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle className={styles.route} cx="50%" cy="50%" r={radius} />
            <circle className={clsx(styles.ani, styles.aniKeyframe)} cx="50%" cy="50%" r={radius} />
        </svg>
    );
};