import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import type { CTALayoutComponent } from "./types";
import { PrivatePaths } from "~/model/paths";
import { makeStyles } from "@material-ui/core/styles";
import { ITabbedNavProps } from "~/components/TabbebNav/types";
import TabbedNav from "~/components/TabbebNav";

const useStyles = makeStyles(() => {
    return ({
        secondTopNav: {
            display: "flex",
            flexFlow: "row nowrap",
            width: "100%",
            minHeight: "68px",
        },
    });
});

export const CTAViewLayout: CTALayoutComponent = (props) => {
    const { pageProps: { companyId, ctaId } } = props;
    const styles = useStyles();

    const tabs: ITabbedNavProps["tabs"] = useMemo(() => {
        return [
            {
                title: "Editor",
                path: `/admin/dealer-management/${companyId}/ctas/${ctaId}` as PrivatePaths,
            },
            {
                title: "Catalog",
                path: `/admin/dealer-management/${companyId}/ctas/${ctaId}/catalogue` as PrivatePaths,
                disabled: true,
            },
        ].filter(Boolean);
    }, [companyId, ctaId]);

    return (
        <Box className={styles.secondTopNav}>
            <TabbedNav
                tabs={tabs}
            />
        </Box>
    );
};
