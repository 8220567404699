import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { useLocation } from "@hooks/use-location";

import Tab from "~/components/Tab";

import type { ITabbedNavProps } from "./types";
import type { ITabProps } from "../Tab/types";

const useStyles = makeStyles((theme) => {
    return {
        tabContainer: {
            borderBottom: `1px solid ${theme.palette.borderInternal.main};`,
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            margin: "32px 0 0",
        },
    };
});

const TabbedNav: React.FC<ITabbedNavProps> = ({
    tabs,
}) => {
    const styles = useStyles();
    const location = useLocation();

    return (
        <Box className={styles.tabContainer}>
            {tabs.map((tabProps, i) => (
                tabProps && (
                    <Tab
                        key={`tab-${tabProps.title}-${tabProps.path}`}
                        location={location}
                        {...tabProps}
                    />
                )
            ))}
        </Box>
    );
};

interface ILooseTabbedNavProps {
    tabs: (
        | (Omit<ITabProps, "location" | "path"> & { path: string; })
        | false
        | undefined
        | null
    )[];
}

export const LooseTabbedNav: React.FC<ILooseTabbedNavProps> = (props) => (
    <TabbedNav {...props as ITabbedNavProps} />
);

export default TabbedNav;