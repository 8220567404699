import { CTA } from "@api/ctas";
import { ITableColumn } from "~/components/Table";

export const ctaColumns: ITableColumn[] = [
    {
        label: "Product",
        property: "product",
        cell: (props): string => `${props}`,
    },
    {
        label: "Type",
        property: "type",
        cell: (props): string => `${props}`,
    },
    {
        label: "Version",
        cell: (props: CTA[]): string => {
            const [cta] = props;
            if (cta.companyCta) {
                return "Custom";
            }
            return `${cta.version}`;
        },
    } as any,
    {
        label: "Published",
        property: "published",
        cell: (props): string => `${props}`,
    },
    {
        label: "Enabled",
        property: "enabled",
        cell: (props): string => `${props}`,
    },
];